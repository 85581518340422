import React, { useContext } from 'react'
import { GlobalDataContext } from "../../context/context";
// import { GiPaintRoller } from "react-icons/gi";


const CounterUp = ({ image }) => {

    const { rpdata } = useContext(GlobalDataContext);


    const counterInfo = [
        {
            title: 'Professional',

        },
        {
            title: 'Affordable',

        },
        {
            title: 'Experienced',

        },
        {
            title: 'Reputable',

        },
    ]

    return (
      <div
        className="bgCountent"
        style={{
          backgroundImage: `url("${image ? image : rpdata?.stock?.[1]}")`,
        }}
      >
        <div
          className="w-[80%] md:w-[30%] flex justify-center mx-auto"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt="CompanyLogo"
            className="w-full flex"
            data-aos="zoom-in"
            data-aos-duration="1500"
          />
        </div>
        <div className="w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {counterInfo.map((items, index) => {
            const colorContent = index % 2 === 0 ? "bg2" : "bg-2";
            return (
              <div
                key={index}
                className={`text-center p-3 relative ${colorContent}`}
              >
                <h3 className="text-white text-[18px]">{items.title}</h3>
              </div>
            );
          })}
          <div></div>
        </div>
      </div>
    );
}
export default CounterUp
