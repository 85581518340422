import React from "react";


function ContentServicesHome() {

  return (
    <>
      <div className="md:w-[98%] mx-auto my-12 text-center">
        <div className="flex md:flex-row flex-col items-start justify-center">
          <div className="w-full md:w-1/2 h-auto border-black">
            <div className="w-full p-5">
              <h3 className="text-2xl font-semibold text-start underline">Residential & Commercial Services</h3>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Clogged Drains</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Water Heater Replacement / Repair</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Tankless Water Heaters</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Sewer Lateral Inspections</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Fixture Replacement</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Water Softener Installation</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Reverse Osmosis Installation</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Repipe / Reroute Piping Systems</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Gas Lines</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Shower / Tub Installation</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Sump Pumps</p>
            </div>
          </div>
          <div className="w-1/2 h-full">
            <div className="w-full p-5">
              <h3 className="text-2xl font-semibold text-start underline">Construction Plumbing</h3>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Tenant Improvements</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Underground</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Top Out Plumbing</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Install Finish Fixtures</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Ground Up </p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• New Home Builds</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Gas lines</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Remodels</p>
            </div>
            <div className="w-full py-2 px-4 text-start">
              <p>• Design Builds</p>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default ContentServicesHome;
