import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import ContentServices from "../components/Home/ContentServicesHome";
import BaseLayout from "../components/global/BaseLayout";

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Services({image, corte}) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Services">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Services"
          Subheader="Services"
          bgimg={`${
            rpdata?.gallery?.length > 3
              ? rpdata?.gallery?.[23]
              : rpdata?.stock?.[3]
          }`}
        />
        <div className="text-center py-10 px-10">
          <h2 className="pb-10">Our Services</h2>
          <p>{rpdata?.dbAbout?.[4]?.text}</p>
          <ContentServices />
        </div>

        <div>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={10}
            slidesPerView={3}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {rpdata?.stock.slice(1, 6).map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className=" w-[80px] lg:w-[150px]  h-[250px] lg:h-[350px] bg-center bg-cover"
                  style={{ backgroundImage: `url("${item}")` }}
                ></SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Services;
